import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Page.css'
import Header from '../../../Header'
import Footer from '../../../Footer'
import Block from '../../components/Block'
import Content from '../../components/Content'
import Head from '../../components/Head'
import Breadcrumb from '../../components/Breadcrumb'
import PageContainer from '../../components/PageContainer'
import BlockHead from '../../components/BlockHead'
import BlockHeadContent from '../../components/BlockHeadContent'
import BlockTitle from '../../components/BlockTitle'

const Developers = ({ ...props }) => {
  const breadcrumbItems = [
    { label: 'Home', href: '/', active: false },
    { label: 'Partnership', href: '/legal', active: false },
    { label: 'Developers', href: '#', active: true }
  ];

  return (
    <React.Fragment>
            <Helmet>
                <title>Developers | Barter Funds</title>
                <meta name="description" content="Review the Affiliate Policy of Barter Funds. Understand the terms, conditions, and guidelines for participating in our affiliate program." />
                <meta name="keywords" content="Barter Funds, Affiliate Program, Affiliate Policy, Digital Assets, Referral Program, Earn Money" />
                <meta name="author" content="Barter Funds" />
                <meta property="og:title" content="Affiliate Policy | Barter Funds" />
                <meta property="og:description" content="Detailed information about Barter Funds' Affiliate Policy, including how to join and the benefits of being an affiliate." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://barter-funds.com/affiliate-policy" />
                <meta property="og:image" content="https://barter-funds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta property="og:site_name" content="Barter Funds" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Affiliate Policy | Barter Funds" />
                <meta name="twitter:description" content="Learn more about the terms and conditions of Barter Funds' affiliate program." />
                <meta name="twitter:image" content="https://barter-funds.com/assets/affiliate-policy-thumbnail.jpg" />
                <meta name="twitter:site" content="@BarterFunds" />
            </Helmet>
      <Header />
      <Head title="Developers | Barter Funds" />
      <Content>
        <BlockHead size="lg" wide="xs" className="mx-auto">
          <BlockHeadContent className="text-center">
            <BlockTitle tag="h2" className="fw-normal">
              Developers
            </BlockTitle>
            <Breadcrumb items={breadcrumbItems} />
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PageContainer bodyClass="page-container">
            <article className="entry">
              
    <>

        <p>
          Welcome to the Developer's Corner of Barterfunds, a leading initiative
          under Currency Technologies. As a pioneer in the Ghanaian fintech space,
          Currency Technologies is committed to fostering innovation and creating
          cutting-edge solutions to drive financial inclusion and economic growth.
        </p>
        <h2 className='sub-title'>About Currency Technologies</h2>
        <p>
          Currency Technologies is at the forefront of financial technology
          development in Ghana. Our mission is to provide innovative and efficient
          financial solutions that cater to the unique needs of the Ghanaian
          market. We leverage advanced technologies to create products and
          services that simplify financial transactions, enhance security, and
          promote financial literacy.
        </p>
        <h2 className='sub-title'>Our Vision</h2>
        <p>
          Our vision is to transform the financial landscape in Ghana by providing
          accessible and user-friendly fintech solutions. We aim to empower
          individuals and businesses through technology, making financial services
          more inclusive and efficient.
        </p>
        <h2 className='sub-title'>Barterfunds: Our Flagship Platform</h2>
        <p>
          Barterfunds is a testament to our commitment to innovation. As a premier
          platform under Currency Technologies, Barterfunds offers seamless and
          secure financial services tailored to the Ghanaian context. We
          prioritize user experience, security, and reliability, ensuring that our
          users can manage their finances with ease and confidence.
        </p>
        <h2 className='sub-title'>Developer Resources</h2>
        <p>
          We believe that collaboration is key to innovation. That's why we
          provide a range of resources and tools for developers to build,
          integrate, and enhance their solutions using our platform. Whether
          you're looking to create new applications, integrate existing ones, or
          simply explore our technology, we have you covered.
        </p>
        <ul className='bullet-point'>
          <li>
            <strong>API Documentation</strong>: Comprehensive guides and
            references to help you integrate with our platform effortlessly.
          </li>
          <li>
            <strong>SDKs</strong>: Software Development Kits for various
            programming languages to streamline your development process.
          </li>
          <li>
            <strong>Sandbox Environment</strong>: A safe and controlled
            environment to test your applications before going live.
          </li>
          <li>
            <strong>Support</strong>: Dedicated support channels to assist you
            with any technical queries or issues you might encounter.
          </li>
        </ul>
        <h2 className='sub-title'>Join Our Developer Community</h2>
        <p>
          Connect with like-minded professionals and stay updated with the latest
          developments, best practices, and industry trends. Our developer
          community is a vibrant space where you can share ideas, seek advice, and
          collaborate on exciting projects.
        </p>
        <ul className='bullet-point'>
          <li>
            <strong>Forums</strong>: Participate in discussions, ask questions,
            and share your knowledge with others.
          </li>
          <li>
            <strong>Meetups &amp; Events</strong>: Join us at our regular meetups,
            hackathons, and workshops to learn, network, and innovate.
          </li>
          <li>
            <strong>Newsletter</strong>: Subscribe to our newsletter for updates
            on new features, upcoming events, and more.
          </li>
        </ul>
        <h2 className='sub-title'>Get Started</h2>
        <p>
          Ready to dive in? Explore our developer portal to access all the
          resources you need to start building with Barterfunds. If you have any
          questions or need further assistance, don't hesitate to reach out to our
          support team.
        </p>
        <p>
          <a href="/coming-soon">Developer Portal Link</a>
        </p>
        <p>
          <strong>Contact Us</strong>:{" "}
          <a href="mailto:developer@barter-funds.com">
            developers@barter-funds.com
          </a>
        </p>
        <p>Let's shape the future of fintech in Ghana together.</p>
    </>


            </article>
          </PageContainer>
        </Block>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default Developers;
